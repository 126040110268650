<template>
   <div class="card mb-0">
      <div class="card-body p-3 text-center">
         <img :src="advertencia.photo == null ? '' : advertencia.photo" alt="Prova" class="cursor-pointer rounded w-100 mb-2" @error="imageError" @click="viewPhoto">
         <h5 class="mb-2 font-16 limitador">{{ advertencia.description }}</h5>
         <p class="m-0 font-13 limitador">
            <i class="far fa-calendar color-theme font-12 me-2"></i><small class="text-secondary">Data:</small> {{ new Date(parseInt(advertencia.date)).toLocaleDateString('pt-BR') }}
         </p>
         <p class="m-0 font-13 limitador"> 
            <i class="far fa-calendar-times color-theme font-12 me-2"></i><small class="text-secondary">Validade:</small> {{ advertencia.validity }} dia(s)
         </p>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'

export default {
	name: 'Advertencia',
   props: ['advertencia', 'index'],
   methods: {
      imageError : function (e) {
         e.target.src = 'https://cdn.discordapp.com/attachments/795099022439481390/1009472987411521546/sem_foto.png'
      },
      viewPhoto : function(e) {
         Swal.fire({
            imageUrl: e.target.src,
            imageHeight: 350,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Fechar'
         });
      }
   }
}

</script>

<style scoped>

.removeToHover {
	cursor: pointer;
}

.removeToHover h5:nth-child(2) {
	display: none !important;
}

.removeToHover h5:nth-child(2) i {
	font-size: 80%;
}

.removeToHover:hover > * {
	display: none !important;
}

.removeToHover:hover h5:nth-child(2) {
	display: block !important;
	margin: 0 !important;
	color: #FF3636;
}

</style>