<template>
	<div class="row m-0">
      <div class="col-12 p-0">
         <div class="card mb-2">
            <div class="card-body pb-2">
               <div class="row m-0">
                  <div class="col-6 col-xl-4 mb-2 px-1">
                     <label><i class="far fa-stream color-theme font-12 me-1"></i> Tipo de pesquisa</label>
                     <v-select :options="['Identidade', 'Status']" v-model="pesquisa.tipo" placeholder="Selecione um tipo" />
                  </div>
                  <div class="col-6 col-xl-4 mb-2 px-1" v-if="pesquisa.tipo == 'Status'">
                     <label><i class="far fa-calendar color-theme font-12 me-1"></i> Status</label>
                     <v-select :options="['Não expirado', 'Expirado']" v-model="pesquisa.status" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-6 col-xl-4 mb-2 px-1" v-else>
                     <label><i class="far fa-hashtag color-theme font-12 me-1"></i> Identidade</label>
                     <input type="text" class="form-control" v-model="pesquisa.id" maxlength="100" v-focus placeholder="Identidade" @keyup.enter="searchAdvs" />
                  </div>
                  <div class="col-6 col-xl-2 mb-2 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="searchAdvs">
                        <i class="fas fa-search"></i>
                     </button>
                  </div>
                  <div class="col-6 col-xl-2 mb-2 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100 regulaTexto" @click="showAddAdv">
                        <i class="fas fa-plus"></i><span class="d-none d-xl-inline ms-2">Adicionar</span>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12 py-5 text-center" v-if="resultado.length == 0">Nenhuma advertência encontrada</div>

      <div class="col-12 p-0" v-else>
         <div class="cards-2">
            <advertencia v-for="(advertencia, index) in resultado" :key="index" :advertencia="advertencia" :index="index" />
         </div>
      </div>

      <!-- Modal advertencia -->
      <div class="modal fade" id="modalAddAdv">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h4 class="modal-title" id="modalAddAdvLabel">Adicionar advertência</h4>
               </div>
               <div class="modal-body py-3 pb-5">
                  <div class="card mb-5">
                     <div class="card-body p-3">
                        <div class="row m-0">
                           <div class="col-12 p-0">
                              <label class="form-label"><i class="far fa-image font-12 color-theme me-1"></i> URL Prova</label>
                              <input type="text" class="form-control" v-model="add.photo" maxlength="250" placeholder="URL Prova" />
                           </div>
                           <div class="col-12 p-0 my-2">
                              <label class="form-label"><i class="far fa-hashtag font-12 color-theme me-1"></i> Identidade</label>
                              <input type="text" class="form-control" v-model="add.id" maxlength="100" placeholder="Identidade" />
                           </div>
                           <div class="col-12 p-0 mb-2">
                              <label class="form-label"><i class="fas fa-ellipsis-h font-12 color-theme me-1"></i> Descrição</label>
                              <textarea class="form-control shadow-none" v-model="add.descricao" maxlength="100" placeholder="Descrição"></textarea>
                           </div>
                           <div class="col-12 p-0">
                              <label class="form-label"><i class="far fa-calendar-times font-12 color-theme me-1"></i> Dias p/ expiração</label>
                              <input type="text" class="form-control" v-model="add.expiracao" maxlength="100" placeholder="Expiração" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-success waves-effect me-2" @click="addAdvUser">
                     <i class="far fa-check font-12 me-2"></i>Salvar
                  </button>
                  <button type="button" class="btn btn-danger btn-simple waves-effect" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import advertencia from '@/components/admin/advertencias/Advertencia.vue'

export default {
	name: 'AdmAdvertências',
   data: function () {
      return {
         add: {'id': '', 'descricao': '', 'expiracao': '', 'photo': ''},
         pesquisa: {'tipo': 'Status', 'id': '', 'status': 'Não expirado'},
         resultado: []
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   components: {
      advertencia
   },
   methods: {
      showAddAdv : function() {
         $('#modalAddAdv').modal('show')
      },
      searchAdvs : function () {
         if (this.pesquisa.tipo == null) {
            this.$toast.fire({
               icon: 'error',
               title: 'Selecione uma tipo de pesquisa!'
            });

            return
         } else if (this.pesquisa.tipo == 'Identidade' && (this.pesquisa.id == null || String(this.pesquisa.id).trim().length == 0)) {
            this.$toast.fire({
               icon: 'error',
               title: 'Informe um valor!'
            });

            return
         } else if (this.pesquisa.tipo == 'Status' && this.pesquisa.status == null) {
            this.$toast.fire({
               icon: 'error',
               title: 'Selecione uma opção!'
            });

            return
         }

         let valor = '';

			switch (this.pesquisa.tipo) {
				case 'Identidade':
					valor = this.pesquisa.id
				break;
				case 'Status':
					valor = this.pesquisa.status
				break;
			}

         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.resultado = []

         this.$axios({
            method: 'get',
            url: this.urlRest +'game/searchAdvs',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername 
            },
            params: {
               tipo: this.pesquisa.tipo,
               valor: valor
            }
         }).then(response => {
				this.resultado = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      addAdvUser : function () {
         if (this.add.id == null || String(this.add.id).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Identidade inválida!'
            });

            return
         } else if (this.add.descricao == null || String(this.add.descricao).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Descrição inválida!'
            });

            return
         } else if (this.add.expiracao == null || String(this.add.expiracao).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Expiração inválida!'
            });

            return
         }

         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'game/addAdvUser',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername
            },
            params:{
               idUser : this.add.id,
               photo : this.add.photo,
               description : this.add.descricao,
               expiration : this.add.expiracao
            }
         }).then(function () {
            ref.add = {'id': '', 'descricao': '', 'expiracao': '', 'photo': ''}

            ref.$toast.fire({
               icon: 'success',
               title: 'Advertência adicionada!'
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalAddAdv').modal('hide')

            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      }
   }
}

</script>