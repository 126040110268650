import { render, staticRenderFns } from "./Advertencia.vue?vue&type=template&id=37d4fd25&scoped=true&"
import script from "./Advertencia.vue?vue&type=script&lang=js&"
export * from "./Advertencia.vue?vue&type=script&lang=js&"
import style0 from "./Advertencia.vue?vue&type=style&index=0&id=37d4fd25&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d4fd25",
  null
  
)

export default component.exports